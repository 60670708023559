import styled, { css } from "styled-components"
import { Grid } from "./globalStyles"

export const GallerySection = styled.section`
  padding-block: var(--space-m);
`

export const GallerySectionInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-n);
`

export const GalleryRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-n);

  /* Tablet Adjustments */
  @media (min-width: 680px) {
    ${Grid}
    padding-block: var(--space-m);
  }
`

export const GalleryText = styled.div`
  /* Tablet Adjustments */
  @media (min-width: 680px) {
    grid-column: 2 / span 4;
    padding-inline: var(--space-n);
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    grid-column: 4 / span 3;
    padding-inline: 0;
  }
`

export const GalleryImage = styled.div`
  .gatsby-image-wrapper {
    aspect-ratio: 1/1;
  }

  ${({ format }) =>
    format === "vt" &&
    css`
      img {
        object-fit: contain !important;
        object-position: center left;
      }
    `}

  /* Tablet Adjustments */
  @media (min-width: 680px) {
    grid-column: 1 / -1;

    .gatsby-image-wrapper {
      aspect-ratio: auto;
      height: 100%;
    }
  }

  /* Desktop Adjustments */
  @media (min-width: 1200px) {
    grid-row: 1;

    ${({ type }) =>
      type === "cover" &&
      css`
        grid-column: 2 / -2;
        height: calc(var(--leading) * 27);
      `}

    ${({ format }) =>
      (format === "hz" &&
        css`
          height: calc(var(--leading) * 16);
          --span-column: span 6;
          --center-start: 4;
        `) ||
      (format === "vt" &&
        css`
          height: calc(var(--leading) * 24);
          --span-column: span 4;
          --center-start: 5;
        `)}

    ${({ position }) =>
      (position === "right" &&
        css`
          grid-column: var(--span-column) / -1;
        `) ||
      (position === "center" &&
        css`
          grid-column: var(--center-start) / var(--span-column);
        `) ||
      (position === "left" &&
        css`
          grid-column: 1 / var(--span-column);
        `) ||
      (position === "center-right" &&
        css`
          grid-column: var(--span-column) / -2;
        `) ||
      (position === "center-left" &&
        css`
          grid-column: 2 / var(--span-column);
        `)}
  }
`
