import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Styles
import { Container } from "../styles/globalStyles"
import {
  GallerySection,
  GallerySectionInner,
  GalleryText,
  GalleryImage,
  GalleryRow,
} from "../styles/galleryStyles"

const Gallery = ({ id, title, description, images }) => {
  const rows = Object.values(
    images.reduce((group, image) => {
      const { rowName } = image
      group[rowName] = group[rowName] ?? []
      group[rowName].push(image)
      return group
    }, {})
  )

  return (
    <GallerySection id={id}>
      <Container>
        <GallerySectionInner>
          <GalleryRow>
            <GalleryText>
              <h2>{title}</h2>
              <p>{description}</p>
            </GalleryText>
          </GalleryRow>
          {rows.map((row, i) => {
            return (
              <GalleryRow key={i}>
                {row.map((image, i) => {
                  return (
                    <GalleryImage
                      key={i}
                      type={image.type}
                      format={image.format}
                      position={image.position}
                    >
                      <GatsbyImage
                        image={getImage(image.image)}
                        alt={image.image.description}
                      />
                    </GalleryImage>
                  )
                })}
              </GalleryRow>
            )
          })}
        </GallerySectionInner>
      </Container>
    </GallerySection>
  )
}

export default Gallery
